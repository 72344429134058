import request from "@/api/request";

export default {
    // 列表
    getList: async data => request({ url: '/console/orders.Complaint/getList', data }),
    // 详情
    getDetail: async data => request({ url: '/console/orders.Complaint/getDetail', data }),
    // 确认投诉
    confirm: async data => request({ url: '/console/orders.Complaint/confirm', data }),
    // 关闭投诉
    close: async data => request({ url: '/console/orders.Complaint/close', data }),
    // 开始处理
    start: async data => request({ url: '/console/orders.Complaint/start', data }),
    // 处理完成
    finish: async data => request({ url: '/console/orders.Complaint/finish', data }),
}
